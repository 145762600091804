.header {
  @apply flex flex-row justify-between p-3 border-b-black items-center bg-indigo-600;
  border-bottom-width: 1px;
}

.header__logo-and-nav {
  @apply flex flex-row justify-start gap-3 items-center;
}

.header__signout-button {
  @apply inline-block rounded-md border border-transparent bg-white px-4 py-2 text-base font-medium text-indigo-600 hover:bg-indigo-50;
}
